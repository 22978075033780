<template>
  <b-card
    id="training-tabs"
    :class="'card-custom gutter-b' + (stretch ? ' card-stretch' : '')"
    :style="{ marginBottom: '25px' }"
    no-body
  >
    <b-tabs
      v-model="tabValue"
      card
      pills
      lazy
      nav-class="nav-light-success nav-bold col"
      :nav-wrapper-class="
        'card-toolbar row pt-2 ' + (object && Object.keys(object).length > 0)
          ? 'pb-4'
          : 'pb-8'
      "
      align="left"
      content-class="card-body text-left"
      @activate-tab="
        ted = {};
        youtube = {};
        vimeo = {};
        iframe = {};
        html = {};
        ownVideo = {};
      "
    >
      <template v-slot:empty>
        <div
          class="text-center text-muted"
          v-html="
            $t('generic.empty', {
              module: title || (object ? object.title : '')
            })
          "
        ></div>
      </template>
      <template v-slot:tabs-start>
        <div class="card-title pt-5">
          <h3
            class="card-label"
            v-html="title || (object ? object.title : '')"
          />
          <div
            class="card-subtitle text-muted"
            v-if="object && Object.keys(object).length > 0"
          >
            {{ moment(object.dateStatus).format("ll") }}
          </div>
        </div>
      </template>
      <template v-slot:tabs-end>
        <b-dropdown
          toggle-tag="a"
          size="sm"
          right
          :class="tabValue >= chunkAt ? 'nav-item active' : 'nav-item'"
          :menu-class="tabValue >= chunkAt ? 'nav-item active' : ''"
          :toggle-class="tabValue >= chunkAt ? 'nav-link active' : 'nav-link'"
          role="button"
          variant="clean"
          v-if="invisibleTabs.length > 0"
        >
          <template v-slot:button-content>
            <span class="nav-icon">
              <i class="flaticon2-gear"></i>
            </span>
            <span class="nav-text">{{ $t("profile.tabs.more") }}</span>
          </template>
          <b-dropdown-item
            v-for="(invisible, invisibleIndex) in invisibleTabs"
            :key="invisible.key"
            @click="changeTab(chunkAt + invisibleIndex)"
            :active="tabValue === chunkAt + invisibleIndex"
          >
            {{
              getTab(
                invisible.actionTypeId,
                invisible.dimensionGroupId,
                invisible,
                invisible.dimensionId
              ).title
            }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <b-tab
        no-body
        lazy
        v-for="(tab, index) in computedTabs"
        :key="index"
        :title-item-class="index >= chunkAt ? 'd-none' : ''"
      >
        <template v-slot:title v-if="index < chunkAt">
          <span class="nav-icon">
            <i
              :class="
                getTab(
                  tab.actionTypeId,
                  tab.dimensionGroupId,
                  tab,
                  tab.dimensionId
                ).icon
              "
            ></i>
          </span>
          <span class="nav-text" v-html="tab.title" />
        </template>
        <span v-if="tab.title && !tab.description" v-html="tab.title" />
        <br v-if="tab.title && !tab.description" />
        <span v-if="tab.description" v-html="tab.description" />
        <div class="assets mt-8" v-if="tab.assets && tab.assets.length > 0">
          <b-button
            tag="a"
            class="mr-4 mb-4 bg-gray-100 font-weight-bold btn-hover-text-dark"
            variant="secondary"
            v-for="asset in tab.assets"
            :key="asset.id"
            :class="'asset-' + asset.type"
            @click="downloadFile(asset)"
          >
            <span v-if="asset.type === 'youtube_iframe'">
              <i class="socicon-youtube text-danger"></i>
              {{ asset.link }}
            </span>
            <span v-else-if="asset.type === 'ted_iframe'">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324 119" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#E62B1E" d="M32.678 30.831H0V.85h101.56v29.981H68.875V118.3H32.678V30.831zM107.18.85h98.75v29.981h-62.55v14.462h62.55v28.212h-62.55v14.814h62.55V118.3h-98.75V.85zm104.72 0h59.39C310.3.85 324 29.773 324 59.401c0 35.975-18.98 58.899-59.74 58.899H211.9V.85zm36.2 87.469h14.05c22.49 0 25.66-18.337 25.66-29.27 0-7.41-2.46-27.865-28.47-27.865h-11.6l.36 57.135z"/></svg>
              {{ asset.link }}
            </span>
            <span v-else-if="asset.type === 'vimeo_iframe'">
              <i class="socicon-vimeo text-danger"></i>
              {{ asset.link }}
            </span>
            <span v-else-if="asset.type === 'iframe'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" x="2" y="6" width="13" height="12" rx="2"/><path d="M22 8.414v7.172a1 1 0 01-1.707.707l-3.586-3.586a1 1 0 010-1.414l3.586-3.586A1 1 0 0122 8.414z" fill="#000" opacity=".3"/></g></svg>
              {{ asset.link }}
            </span>
            <span v-else-if="asset.type === 'audea_link'" class="audea-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="12" cy="12" r="10"/><rect fill="#000" x="11" y="7" width="2" height="8" rx="1"/><rect fill="#000" x="11" y="16" width="2" height="2" rx="1"/></g></svg>
              {{ asset.link }}
            </span>
            <span v-else-if="asset.type === 'external_link'">
              <i class="fas fa-link"></i> {{ asset.link }}
            </span>
            <span v-else>
              <i :class="'fas fa-file-' + asset.type"></i> {{ asset.link }}
            </span>
          </b-button>
        </div>
        <Youtube
          v-if="Object.keys(youtube).length > 0"
          :code="youtube.content"
        />
        <Ted v-else-if="Object.keys(ted).length > 0" :code="ted.content" />
        <Vimeo
          v-else-if="Object.keys(vimeo).length > 0"
          :code="vimeo.content"
        />
        <Iframe
          v-else-if="Object.keys(iframe).length > 0"
          :code="iframe.content"
        />
        <OwnVideo
          v-else-if="Object.keys(ownVideo).length > 0"
          :code="ownVideo.content"
        />
        <ImageAsset
          v-else-if="Object.keys(image).length > 0"
          :code="image.content"
        />
      </b-tab>
    </b-tabs>
    <Html
      v-if="showHtml"
      :showAsset="showHtml"
      :code="html.content"
      @asset-closed="showHtml = false"
      :name="title"
    />
    <pdf
      v-if="showPdf"
      :base64="showingAsset"
      :showAsset="showPdf"
      :name="pdfName"
      @asset-closed="showPdf = false"
    />
  </b-card>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";

import { mapGetters } from "vuex";

import Pdf from "@/components/assets/Pdf.component";
import Youtube from "@/components/assets/Youtube.component";
import Ted from "@/components/assets/Ted.component";
import Vimeo from "@/components/assets/Vimeo.component";
import Iframe from "@/components/assets/Iframe.component";
import Html from "@/components/assets/Html.component";
import ImageAsset from "@/components/assets/ImageAsset.component";
import OwnVideo from "@/components/assets/OwnVideo.component";

import { getError } from "@/utils/helpers";

export default {
  components: {
    Pdf,
    Youtube,
    Ted,
    Vimeo,
    Iframe,
    Html,
    ImageAsset,
    OwnVideo
  },
  data() {
    return {
      tabValue: 0,
      moment: moment,
      showingAsset: "",
      showPdf: false,
      showHtml: false,
      pdfName: "Kymatio.pdf",
      chunkAt: 100,
      youtube: {},
      ted: {},
      vimeo: {},
      ownVideo: {},
      iframe: {},
      html: {},
      zip: {},
      image: {},
      link: {},
      xlsx: {},
      pdf: {},
      excel: {}
    };
  },
  props: {
    title: {
      type: String,
      required: false
    },
    avatar: {
      type: String,
      required: false
    },
    tabs: {
      type: Array,
      required: true
    },
    object: {
      type: Object,
      required: false
    },
    stretch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    invisibleTabs() {
      return _.slice(this.computedTabs, this.chunkAt);
    },
    computedTabs() {
      var result = [];
      _.forEach(this.tabs, action => {
        var obj = {
          id:
            action.actionTypeId.toFixed(0) +
            action.dimensionGroupId.toFixed(0) +
            action.dimensionId.toFixed(0),
          actionTypeId: action.actionTypeId,
          dimensionGroupId: action.dimensionGroupId,
          dimensionId: action.dimensionId,
          title: action.executeAction.title || false,
          description: action.executeAction.description || false,
          assets: action.executeAction.assets || []
        };
        result.push(obj);
      });
      return result;
    }
  },
  methods: {
    callGetError(code, params) {
      return getError(code, params);
    },
    getAssetIcon(type) {
      var result = {};
      switch (type) {
        case "youtube_iframe":
          result.class = "socicon-youtube text-danger";
          break;
        case "ted_iframe":
          result.file = "files/ted.svg";
          break;
        case "vimeo_iframe":
          result.class = "socicon-vimeo text-danger";
          break;
        case "iframe":
          result.image = "file.svg";
          break;
        case "audea_link":
          result.image = "warning-circle.svg";
          break;
        case "external_link":
          result.class = "fas fa-link";
          break;
        case "excel":
        case "image":
        case "xlsx":
          result.image = "image-svg";
          break;
        case "zip":
          result.image = "zip.svg";
          break;
        case "html":
          result.image = "attachment.svg";
          break;
        case "own_video":
          result.image = "attachment.svg";
          break;
        default:
          result.class = `fas fa-file-${type}`;
          break;
      }
      return result;
    },
    getTab(actionTypeId, dimensionGroupId, tab, dimensionId = false) {
      var result = {};
      switch (parseInt(dimensionGroupId)) {
        case parseInt(this.getConstant("DIMENSION_GROUP_INSIDER")):
          if (
            parseInt(actionTypeId) ===
            parseInt(this.getConstant("ACTIONS_TYPE_RECOMMENDATION"))
          ) {
            result.icon = "fas fa-hands-helping";
            result.title = this.$t("training.detail.security.recommendation");
          } else if (
            parseInt(actionTypeId) ===
            parseInt(this.getConstant("ACTIONS_TYPE_PILLS"))
          ) {
            result.icon = "fas fa-capsules";
            result.title = this.$t("training.detail.security.pills");
          }
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_HAZARD")):
          switch (parseInt(dimensionId)) {
            case parseInt(
              this.getConstant("DIMENSION_ELEMENT_CONFIDENTIALITY_HAZARD")
            ):
              result.icon = "fas fa-user-secret";
              break;
            case parseInt(
              this.getConstant("DIMENSION_ELEMENT_INTEGRITY_HAZARD")
            ):
              result.icon = "fas fa-user-shield";
              break;
            case parseInt(
              this.getConstant("DIMENSION_ELEMENT_AVAILABILITY_HAZARD")
            ):
              result.icon = "fas fa-check-double";
              break;
          }
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_GRIPLUS")):
          result.icon = "fas fa-hands-helping";
          result.title = this.$t("training.detail.security.recommendation");
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_RECOMEND_CLIMATE")):
          result.icon = "fas fa-globe-europe";
          result.title = this.$t("training.detail.wellbeing.recommendation");
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_CIBERSECURITY")):
          if (dimensionId !== false) {
            switch (parseInt(dimensionId)) {
              case parseInt(
                this.getConstant("DIMENSION_ELEMENT_DATAPROTECTION")
              ):
                result.icon = "fas fa-lock";
                break;
              case parseInt(this.getConstant("DIMENSION_ELEMENT_MALWARE")):
                result.icon = "fas fa-bug";
                break;
              case parseInt(
                this.getConstant("DIMENSION_ELEMENT_COMMUNICATIONS")
              ):
                result.icon = "fas fa-wifi";
                break;
              case parseInt(
                this.getConstant("DIMENSION_ELEMENT_PASSWORDMANAGEMENT")
              ):
                result.icon = "fas fa-key";
                break;
              case parseInt(this.getConstant("DIMENSION_ELEMENT_WORKPLACE")):
                result.icon = "fas fa-mail-bulk";
                break;
              case parseInt(
                this.getConstant("DIMENSION_ELEMENT_SOCIALENGINEERING")
              ):
                result.icon = "fas fa-mask";
                break;
              case parseInt(this.getConstant("DIMENSION_ELEMENT_COMPLIANCE")):
                result.icon = "fas fa-balance-scale";
                break;
              default:
                result.icon = "fas fa-shield-alt";
                break;
            }
          } else {
            result.icon = "fas fa-shield-alt";
          }
          result.title = tab.title;
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_SOCIAL_ENGINEERING")):
          switch (parseInt(actionTypeId)) {
            case parseInt(this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM")):
              result.icon = "fas fa-crosshairs";
              break;
            case parseInt(this.getConstant("ACTIONS_TYPE_SE_GENERAL")):
              result.icon = "fas fa-clipboard-list";
              break;
            case parseInt(this.getConstant("ACTIONS_TYPE_SE_RECOMENDATION")):
              result.icon = "fab fa-readme";
              break;
            default:
              result.icon = "fas fa-share-alt";
              break;
          }
          result.title = tab.title;
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_BURNOUT")):
          switch (parseInt(dimensionId)) {
            case parseInt(this.getConstant("DIMENSION_ELEMENT_BURNOUT_NOTE")):
            case parseInt(
              this.getConstant("DIMENSION_ELEMENT_BURNOUT_MEDIUM_NOTE")
            ):
              result.icon = "fas fa-fire";
              break;
          }
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_CUSTOM")):
          switch (parseInt(dimensionId)) {
            case parseInt(this.getConstant("DIMENSION_ELEMENT_CUSTOM_RESULT")):
              result.icon = "fas fa-chart-simple";
              break;
            default:
              result.icon = "fas fa-info";
              break;
          }
          break;
        default:
          break;
      }
      return result;
    },
    changeTab(index) {
      this.tabValue = index;
    },
    downloadFile(asset, automatic = true) {
      this.ted = {};
      this.youtube = {};
      this.vimeo = {};
      this.iframe = {};
      this.html = {};
      this.own_video = {};
      this.axios
        .get(asset.url)
        .then(response => {
          if (automatic) {
            if (
              response.data.records.forward &&
              asset.type !== "youtube_iframe" &&
              asset.type !== "ted_iframe" &&
              asset.type !== "vimeo_iframe" &&
              asset.type !== "excel" &&
              asset.type !== "image" &&
              asset.type !== "xlsx" &&
              asset.type !== "zip" &&
              asset.type !== "html" &&
              asset.type !== "iframe" &&
              asset.type !== "own_video"
            ) {
              let a = document.createElement("a");
              a.href = response.data.records.content;
              a.target = "_blank";
              a.click();
            } else {
              switch (asset.type) {
                case "pdf":
                  this.showingAsset = response.data.records.content;
                  this.showPdf = true;
                  break;
                case "youtube_iframe":
                  this.youtube = response.data.records;
                  this.showed = true;
                  break;
                case "ted_iframe":
                  this.ted = response.data.records;
                  this.showed = true;
                  break;
                case "vimeo_iframe":
                  this.vimeo = response.data.records;
                  this.showed = true;
                  break;
                case "excel":
                  this.excel = response.data.records;
                  this.showed = true;
                  break;
                case "image":
                  this.image = response.data.records;
                  this.showed = true;
                  break;
                case "xlsx":
                  this.xlsx = response.data.records;
                  this.showed = true;
                  break;
                case "zip":
                  this.zip = response.data.records;
                  this.showed = true;
                  break;
                case "html":
                  this.html = response.data.records;
                  this.showHtml = true;
                  break;
                case "iframe":
                  this.iframe = response.data.records;
                  this.showed = true;
                  break;
                case "own_video":
                  this.ownVideo = response.data.records;
                  this.showed = true;
                  break;
                default:
                  return response.data.records;
              }
            }
          } else {
            return response.data.records.content;
          }
        })
        .catch(error => {
          if (error.response.status === 412) {
            this.$swal(this.callGetError("file_not_found"), "", "error");
          }
        });
    }
  }
};
</script>

<style lang="scss">
#training-tabs {
  .nav {
    .nav-item {
      &.dropdown {
        margin-top: 0rem;
      }
      margin-top: 0rem;
      margin-bottom: 5px;
    }
    .nav-link {
      padding: 0.75rem 0.8rem !important;
    }
  }
  .card-title {
    position: relative;
    min-width: 100%;
    padding-bottom: 30px !important;
    .card-subtitle {
      position: absolute;
      top: 3.8rem !important;
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
}
.fa-file-html:before {
  content: "\f0c1";
}
.asset-audea_link .svg-icon.svg-icon-primary svg g [fill] {
  fill: #005765 !important;
}
</style>
